import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PianificazioneService } from '../../../admin/services/pianificazione.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pianificazione',
  templateUrl: './pianificazione.component.html',
  styleUrls: ['./pianificazione.component.css']
})
export class PianificazioneComponent implements OnInit {

  downloadUrl: SafeUrl | null = null;
  documentExists: boolean = false; // Variabile per tracciare la disponibilità del documento

  constructor(
    private pianificazioneService: PianificazioneService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.checkAndDownloadDocument();
  }

  checkAndDownloadDocument(): void {
    this.pianificazioneService.checkDocumentExists().subscribe({
      next: (response) => {
        this.documentExists = true; // Il documento esiste
        this.downloadDocument();
      },
      error: (error) => {
        this.documentExists = false; // Il documento non esiste
        console.error('Document does not exist or an error occurred:', error);
      }
    });
  }

  downloadDocument(): void {
    this.pianificazioneService.getPianificazione().subscribe(
      blob => {
        const unsafeUrl = window.URL.createObjectURL(blob);
        this.downloadUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
        this.cdr.detectChanges();
      },
      error => {
        console.error('Error downloading the file:', error);
      }
    );
  }
}
