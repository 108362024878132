import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastService} from './toast.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(public toastService: ToastService, public router: Router){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    if (this.toastService.get().length === 0){
                      this.toastService.show((event.body?.message) ? event.body.message : 'Operazione completata', {class: 'bg-success text-light', delay: 3000});
                    }
                }
            }),
            catchError((error) => {
                if (error.status === 401){
                    this.toastService.show((error.error.errors) ? this.getErrors(error.error.errors) : error.error.message, {class: 'bg-danger text-light', delay: 5000});
                    switch (this.router.url.split('/')[1]){
                      case 'otp':
                        this.router.navigate(['otp/login']);
                        break;
                      default:
                        this.router.navigate(['login']);
                    }
                    return throwError(error);
                }
                else{
                    this.toastService.show(
                      (Array.isArray(error.error.message)) ? this.getErrors(error.error.message) : error.error.message,
                                           {class: 'bg-danger text-light', delay: 10000}
                                          );
                    return throwError(error);
                }
            })
            );
    }

    getErrors(errors: string[]): string{
       return(errors.join("<br>"));
    }
}
