import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {
  progetto = environment.project;

  constructor(
    private router: Router,
    private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | UrlTree {
    // Permette l'accesso alla route 'pianificazione' anche se non autenticato
    if (state.url.includes('pianificazione') && (this.progetto === 'clemps' || this.progetto === 'vera')) {
      return true;
    }

    // Verifica se l'utente è loggato
    if (this.auth.isUserLogged()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
