import {Component, ElementRef, HostBinding, OnInit, Renderer2} from '@angular/core';
import {SidebarEventService} from './admin/services/sidebar-event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  sidebarOpen = true;
  baseClass = 'sidebar-mini layout-fixed';
  class = '';
  title = 'hrss-web';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private sidebarEventService: SidebarEventService
  ) {
  }

  @HostBinding('class') get HeadingClass(): string {
    return this.class;
  }

  ngOnInit(): void {
    this.sidebarEventService.getSidebarState().subscribe(
      () => {
        this.sidebarOpen = !this.sidebarOpen;
        this.class = (this.sidebarOpen) ? `${this.baseClass} sidebar-open` : `${this.baseClass} sidebar-collapse`;
      }
    );
  }
}
