import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RouteGuardService} from './shared/services/route-guard.service';
import {AdminComponent} from './admin/admin.component';
import {OtpComponent} from './otp/otp.component';
import {PianificazioneComponent} from './shared/components/pianificazione/pianificazione.component';

const routes: Routes = [
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  {path: 'otp', loadChildren: () => import('./otp/otp.module').then(m => m.OtpModule), component: OtpComponent},
  {
    path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [RouteGuardService],
    component: AdminComponent
  },
  {path: 'pianificazione', component: PianificazioneComponent},
  {path: '', redirectTo: 'admin', pathMatch: 'full'},
  {path: '**', redirectTo: 'admin', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {bindToComponentInputs: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
