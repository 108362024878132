import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class PianificazioneService extends HttpService {
  
  checkDocumentExists(): Observable<any> {
    return this.getNoAuth('pianificazione/check');
  }
  
  getPianificazione(): Observable<Blob> {
    return this.getNoAuth<Blob>('pianificazione', { responseType: 'blob' });
  }

}
