<h1 style="text-align: center">Pianificazione</h1>
<p style="text-align: center">
  Accedere al link per visualizzare il documento
</p>
<div style="text-align: center">
  <a [href]="downloadUrl" download="pianificazione.pdf">Scarica il PDF</a>
</div>

<ng-template #preparing>
  <p style="text-align: center">Il documento è in corso di preparazione</p>
</ng-template>
