import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './shared/services/auth.service';
import {RouteGuardService} from './shared/services/route-guard.service';
import {SharedModule} from './shared/shared.module';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpRequestInterceptor} from './shared/services/http-request-interceptor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        LoadingBarHttpClientModule,
        NgbModule,
        BrowserAnimationsModule], providers: [
        RouteGuardService,
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
